<template>
  <!--
    Lists information about a video-session and its processing state.
  -->
  <div class="videoPostProcessing">
    <LoadingPlaceholder v-if="loading == true" />
    <template v-else>
      <!-- ok -->
      <div v-if="currentOrder != null">
        <h4 class="m-0 p-0 mt-4">
          {{ $t('videoPublisherDetailsComp.videoPostprocessing') }}
        </h4>
        <hr class="m-0 p-0 mb-3 mt-1">
        <table
          c
          class="defaultTable"
        >
          <colgroup>
            <col width="160">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.status') }}</th>
              <td>{{ currentOrder.status }}</td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.configAnalysisStatus') }}</th>
              <td>{{ currentOrder.configAnalysisStatus }}</td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.created') }}</th>
              <td
                v-tooltip="dateTime_fromNow(currentOrder.createDate)"
                data-toggle="tooltip"
                data-placement="top"
              >
                {{ currentOrder.createDate | dateTime_dateTime }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.start') }}</th>
              <td
                v-tooltip="dateTime_fromNow(currentOrder.startTime)"
                data-toggle="tooltip"
                data-placement="top"
              >
                {{ currentOrder.startTime | dateTime_dateTime }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.end') }}</th>
              <td
                v-tooltip="dateTime_fromNow(currentOrder.endTime)"
                data-toggle="tooltip"
                data-placement="top"
              >
                {{ currentOrder.endTime | dateTime_dateTime }}
              </td>
            </tr>
            <tr>
              <th valign="top">
                {{ $t('videoPublisherDetailsComp.postprocessingMessages') }}
              </th>
              <td>
                <ul
                  id="list-group-two"
                  class="list-group collapse show"
                >
                  <li
                    v-for="(message, index) in currentOrder.postProcessingMessages"
                    :key="`videoPublisher-messageLi-${ index }`"
                    :class="`${message.type} list-group-item d-flex justify-content-between align-items-center`"
                  >
                    {{ message.message }}                
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.processingDuration') }}</th>
              <td>{{ currentOrder.processingDuration | numberFilter_threeDecimals }} {{ $t('videoPublisherDetailsComp.seconds') }}</td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.sessionId') }}</th>
              <td>{{ currentOrder.sessionInfo.sessionId }}</td>
            </tr>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.startNumber') }}</th>
              <td>{{ currentOrder.startNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 class="m-0 p-0 mt-4">
        {{ $t('videoPublisherDetailsComp.publisherSession') }}
      </h4>
      <hr class="m-0 p-0 mb-3 mt-1">
      <table
        v-if="publisherSession"
        class="defaultTable"
      >
        <colgroup>
          <col width="160">
          <col>
        </colgroup>
        <tbody>
          <tr v-if="!currentOrder">
            <th>{{ $t('videoPublisherDetailsComp.orderId') }}</th>
            <td>{{ publisherSession.orderId }}</td>
          </tr>
          <tr>
            <th>{{ $t('videoPublisherDetailsComp.processingStart') }}</th>
            <td
              data-toggle="tooltip"
              data-placement="top"
            >
              {{ publisherSession.startTime }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('videoPublisherDetailsComp.uploadDate') }}</th>
            <td
              data-toggle="tooltip"
              data-placement="top"
            >
              {{ publisherSession.endTime }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else-if="loading == false">
        {{ $t('noDataAvailable') }}
      </p>
      <div v-if="currentSession && currentSession.events && currentSession.events.length > 0">
        <table
          c
          class="defaultTable"
        >
          <colgroup>
            <col width="160">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('videoPublisherDetailsComp.sessionEvents') }}</th>
              <td class="p-0 m-0">
                <table class="defaultTable m-0 p-0">
                  <colgroup>
                    <col width="110">
                    <col>
                    <col width="60">
                    <col>
                    <col width="60">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{{ $t('videoPublisherDetailsComp.timestamp') }}</th>
                      <th>{{ $t('videoPublisherDetailsComp.eventKey') }}</th>
                      <th>{{ $t('videoPublisherDetailsComp.eventIndex') }}</th>
                      <th>{{ $t('videoPublisherDetailsComp.eventReporter') }}</th>
                      <th>{{ $t('videoPublisherDetailsComp.index') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(event, index) in currentSession.events"
                      :key="`videoPublisherDetails-eventTableRow-${ index }`"
                    >
                      <td
                        v-tooltip="dateTime_fromNow(event.timestamp)"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        {{ dateTime_timeMilliseconds(event.timestamp) }}
                      </td>
                      <td>{{ event.type }}</td>
                      <td>{{ event.eventIndex }}</td>
                      <td>{{ event.source }}</td>
                      <td>{{ event.sourceIndex }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- ok -->
      <button 
        v-if="true" 
        class="btn btn-primary float-right mt-2 mb-2"
        @click.prevent="reprocess()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="sync-alt"
        />{{ $t('videoPublisherComp.reprocess') }}
      </button>
      <div class="clearfix" />
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { numberFilterMixin } from '@/mixins/numberFilterMixin.js';

export default {
  name: 'VideoPostProcessing',
  mixins: [
    dateTimeMixin,
    numberFilterMixin
  ],
  props: {
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      installationId: null,
      publisherSession: null,
      currentOrder: null,
      currentSession: null,
      loading: true
    }
  },
  computed: {
    messagesAvailable () {
      if (!this.currentOrder) {
        return false;
      }
      if (!this.currentOrder.postProcessingMessages) {
        return false;
      }
      if (!this.currentOrder.postProcessingMessages.length) {
        return false;
      }
      if (!this.currentOrder.postProcessingMessages.length > 0) {
        return false;
      }
      return true;
    }
  },
  methods: {
    async getVideoPostProcessingOrder ()
    {
      let url = `/VideoPostProcessing/GetOrder?installationId=${ this.installationId }&orderId=${ this.publisherSession.orderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      await this.axios.get(url)
        .then((response) => {
            this.currentOrder = response.data;
        })
        .catch(() => {})
        .finally(() => {
          //this.loading = false;
        });
    },
    async getVideoPublisherSession ()
    {
      let url = `/VideoPublisher/GetSession/${ this.installationId }/${ this.publisherSession.sessionId }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      await this.axios.get(url)
        .then((response) => {
            this.currentSession = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async loadData (installationId, session)
    {
      this.currentOrder = null;
      this.installationId = installationId;
      this.publisherSession = session;
      if (this.publisherSession == null) {
        this.loading = false;
        return;
      }
      if (this.publisherSession.orderId)
      {
        await this.getVideoPostProcessingOrder();
      }
      await this.getVideoPublisherSession();
    },
    reprocess ()
    {
      let url = `/VideoPublisher/Reprocess/${ this.installationId }/${ this.publisherSession.sessionId}`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.status) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.$snotify.success(this.$t('videoPublisherComp.reprocessingSuccesfull'));
          this.$emit('close');
        });
    },
    clearData ()
    {
      this.installationId = null;
      this.publisherSession = null;
    }
  }
}
</script>

<style scoped>
th {
  background: rgb(199, 199, 199);
}
.error{
	float: left;
  border-left: 4px solid red; 
}
.info{
	float: left;
  border-left: 4px solid blue; 
}
.warning{
	float: left;
  border-left: 4px solid orange; 
}
</style>